import { CREATE_REFERRAL_PARTNER } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';

export const createReferralPartner =
    ({
        name,
        url,
        imageUrl,
        promoCode
    }: {
        name: string;
        url: string;
        imageUrl?: string;
        promoCode?: string;
    }) =>
    async (dispatch: any, getState: any) => {
        try {
            const { data } = await client.mutate({
                mutation: CREATE_REFERRAL_PARTNER,
                variables: {
                    name,
                    url,
                    imageUrl,
                    promoCode
                },
                fetchPolicy: 'network-only'
            });

            return data?.createReferralPartner;
        } catch (error) {
            console.log({ error });
            return error.message;
        }
    };
