import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    DELETE_REFERRAL_PARTNER,
    UPDATE_REFERRAL_PARTNER
} from '../../../api/graphQL/enterprise/mutator';
import EditReferralPartner from './EditReferralPartner';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Switch from '@radix-ui/react-switch';
import {
    DotsHorizontalIcon,
    Pencil1Icon,
    TrashIcon,
    CopyIcon
} from '@radix-ui/react-icons';
import { toast } from 'sonner';

export default function ReferralPartnerCard({ partner, onRefetch }) {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [deletePartner] = useMutation(DELETE_REFERRAL_PARTNER);
    const [updatePartner, { loading: isUpdating }] = useMutation(
        UPDATE_REFERRAL_PARTNER
    );
    const fullLink = `${window.location.origin}?ref=${partner.referralLink}`;

    console.log(partner);

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this partner?')) {
            try {
                await deletePartner({
                    variables: { partnerId: partner._id }
                });
                toast.success('Partner deleted successfully');
                onRefetch();
            } catch (error) {
                toast.error('Error deleting partner');
            }
        }
    };

    const handleActiveToggle = async checked => {
        try {
            await updatePartner({
                variables: {
                    partnerId: partner._id,
                    name: partner.name,
                    url: partner.url,
                    imageUrl: partner.imageUrl,
                    active: checked
                }
            });
            onRefetch();
            toast.success('Partner status updated successfully');
        } catch (error) {
            console.error('Error updating partner status', error);
            toast.error('Error updating partner status');
        }
    };

    const copyReferralLink = () => {
        navigator.clipboard.writeText(fullLink);
        toast.success('Referral link copied to clipboard');
    };

    return (
        <div className="rounded-lg border bg-white shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center justify-between space-y-0 border-b p-4">
                <div className="flex items-center gap-3">
                    {partner.imageUrl && (
                        <img
                            src={partner.imageUrl}
                            alt={partner.name}
                            className="h-12 w-12 object-contain rounded-lg"
                        />
                    )}
                    <h3 className="text-xl font-bold">{partner.name}</h3>
                </div>
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                        <button className="h-8 w-8 p-0 hover:bg-gray-100 rounded-full flex items-center justify-center">
                            <DotsHorizontalIcon className="h-4 w-4" />
                        </button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content
                        className="min-w-[8rem] rounded-md bg-white p-1 shadow-md"
                        align="end"
                    >
                        <DropdownMenu.Item
                            className="flex cursor-pointer items-center rounded-sm px-2 py-1.5 outline-none hover:bg-gray-100"
                            onClick={() => setIsEditModalOpen(true)}
                        >
                            <Pencil1Icon className="mr-2 h-4 w-4" />
                            Edit
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                            className="flex cursor-pointer items-center rounded-sm px-2 py-1.5 outline-none hover:bg-gray-100"
                            onClick={copyReferralLink}
                        >
                            <CopyIcon className="mr-2 h-4 w-4" />
                            Copy Link
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                            className="flex cursor-pointer items-center rounded-sm px-2 py-1.5 outline-none text-red-600 hover:bg-gray-100"
                            onClick={handleDelete}
                        >
                            <TrashIcon className="mr-2 h-4 w-4" />
                            Delete
                        </DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu.Root>
            </div>

            <div className="p-4 space-y-4">
                <div>
                    <label className="text-sm font-medium text-gray-500">
                        Website URL
                    </label>
                    <a
                        href={partner.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block text-blue-600 hover:underline truncate mt-1"
                    >
                        {partner.url}
                    </a>
                </div>

                <div>
                    <label className="text-sm font-medium text-gray-500">
                        Referral Link
                    </label>
                    <div className="flex items-center gap-2 mt-1">
                        <code className="block bg-gray-100 px-2 py-1.5 rounded text-sm flex-1 font-mono">
                            {fullLink}
                        </code>
                        <button
                            className="h-8 w-8 rounded-full hover:bg-gray-100 flex items-center justify-center"
                            onClick={copyReferralLink}
                        >
                            <CopyIcon className="h-4 w-4" />
                        </button>
                    </div>
                </div>

                {partner.promoCode && (
                    <div>
                        <label className="text-sm font-medium text-gray-500">
                            Promo Code
                        </label>
                        <p className="mt-1">{partner.promoCode}</p>
                    </div>
                )}

                <div className="flex items-center justify-between pt-2">
                    <label className="text-sm font-medium text-gray-500">
                        Active
                    </label>
                    <Switch.Root
                        checked={partner.active}
                        disabled={isUpdating}
                        onCheckedChange={handleActiveToggle}
                        className="w-[42px] h-[25px] bg-gray-200 rounded-full relative data-[state=checked]:bg-green-500 outline-none cursor-pointer disabled:cursor-not-allowed"
                    >
                        <Switch.Thumb className="block w-[21px] h-[21px] bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                    </Switch.Root>
                </div>
            </div>

            <EditReferralPartner
                partner={partner}
                isOpen={isEditModalOpen}
                onClose={() => {
                    setIsEditModalOpen(false);
                    try {
                        setTimeout(() => {
                            document.body.style.pointerEvents = '';
                        }, 100);
                    } catch (error) {
                        console.error('Error resetting pointer events', error);
                    }
                }}
                onSuccess={() => {
                    setIsEditModalOpen(false);
                    try {
                        setTimeout(() => {
                            document.body.style.pointerEvents = '';
                        }, 100);
                    } catch (error) {
                        console.error('Error resetting pointer events', error);
                    }
                    onRefetch();
                }}
            />
        </div>
    );
}
