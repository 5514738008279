import React, { useState } from 'react';
import ReferralPartnerCard from './ReferralPartnerCard';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

export function ReferralPartnerList({ partners, onRefetch }) {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredPartners = partners.filter(
        partner =>
            partner.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            partner.referralLink
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            {/* <div className="relative mb-6">
                <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                    type="text"
                    placeholder="Search partners..."
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 pl-10 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-transparent"
                />
            </div> */}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredPartners.map(partner => (
                    <ReferralPartnerCard
                        key={partner._id}
                        partner={partner}
                        onRefetch={onRefetch}
                    />
                ))}
            </div>

            {filteredPartners.length === 0 && (
                <div className="text-center text-gray-500 mt-8">
                    No referral partners found
                </div>
            )}
        </div>
    );
}
