import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_REFERRAL_PARTNERS } from '../../../api/graphQL/enterprise/query.js';
import { ReferralPartnerList } from './ReferralPartnerList.jsx';
import CreateReferralPartner from './CreateReferralPartner.jsx';
import { PlusIcon } from '@radix-ui/react-icons';

export function ReferralPartners() {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const { loading, error, data, refetch } = useQuery(GET_REFERRAL_PARTNERS);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading referral partners</div>;

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Referral Partners</h1>
                <button
                    onClick={() => setIsCreateModalOpen(true)}
                    className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                    <PlusIcon className="h-4 w-4" />
                    Add Partner
                </button>
            </div>

            <ReferralPartnerList
                partners={data.getReferralPartners}
                onRefetch={refetch}
            />

            <CreateReferralPartner
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                onSuccess={() => {
                    setIsCreateModalOpen(false);
                    refetch();
                }}
            />
        </div>
    );
}
