import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { UsersIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const ReferralPartners = ({ closeSidebar }) => {
    const navigate = useNavigate();
    const { enterpriseOrganizationId } = useParams();
    const { pathname } = useLocation();

    const onClick = e => {
        e.preventDefault();
        navigate(
            `/enterprise/dashboard/${enterpriseOrganizationId}/referralPartners`
        );
        if (closeSidebar) {
            closeSidebar();
        }
    };

    const active = pathname.includes('referralPartners');

    if (
        enterpriseOrganizationId != process.env.REACT_APP_PREDEV_ORGANIZATION_ID
    ) {
        return null;
    }

    return (
        <a
            onClick={onClick}
            className={classNames(
                'text-gray-100 hover:text-indigo-300 hover:bg-gray-600',
                'group flex items-center gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold cursor-pointer',
                active ? 'bg-gray-600' : ''
            )}
        >
            <UsersIcon
                className="text-gray-400 flex-shrink-0 h-6 w-6"
                aria-hidden="true"
            />
            <span className="truncate">Referral Partners</span>
        </a>
    );
};
