import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Dialog from '@radix-ui/react-dialog';
import { createReferralPartner } from '../../../redux/actions/enterprise/createReferralPartner';
import { ImageField } from './helpers/ImageField';
import { toast } from 'sonner';

export default function CreateReferralPartner({ isOpen, onClose, onSuccess }) {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        url: '',
        imageUrl: '',
        promoCode: ''
    });

    const handleSubmit = async e => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            if (!formData.name || !formData.url) {
                throw new Error('Name and URL are required');
            }

            if (formData.url && !formData.url.startsWith('http')) {
                throw new Error('Please enter a valid URL');
            }

            await dispatch(
                createReferralPartner({
                    name: formData.name,
                    url: formData.url,
                    imageUrl: formData.imageUrl || undefined,
                    promoCode: formData.promoCode || undefined
                })
            );

            toast.success('Partner created successfully');
            setFormData({ name: '', url: '', imageUrl: '', promoCode: '' });
            onSuccess();
        } catch (error) {
            toast.error(error.message || 'Error creating partner');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleChange = e => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <Dialog.Root open={isOpen} onOpenChange={onClose}>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/50" />
                <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-[90vw] max-w-[500px] max-h-[85vh] overflow-y-auto">
                    <Dialog.Title className="text-xl font-semibold mb-4">
                        Create Referral Partner
                    </Dialog.Title>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="flex flex-col gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Partner Name
                                </label>
                                <input
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter partner name"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Website URL
                                </label>
                                <input
                                    name="url"
                                    value={formData.url}
                                    onChange={handleChange}
                                    placeholder="https://example.com"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Promo Code
                                </label>
                                <input
                                    name="promoCode"
                                    value={formData.promoCode}
                                    onChange={handleChange}
                                    placeholder="Enter promo code"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>

                            <ImageField
                                field="imageUrl"
                                title="Partner Logo"
                                onChange={value =>
                                    setFormData(prev => ({
                                        ...prev,
                                        imageUrl: value || ''
                                    }))
                                }
                                initialValue={formData.imageUrl}
                            />
                        </div>

                        <div className="flex justify-end gap-4 pt-4">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50"
                            >
                                {isSubmitting
                                    ? 'Creating...'
                                    : 'Create Partner'}
                            </button>
                        </div>
                    </form>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}
