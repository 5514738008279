import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { v4 as uuid } from 'uuid';
import { getSignedUploadURL } from '../../../../redux/actions/solo/getSignedUploadURL';
import { toast } from 'sonner';

export const ImageField = ({ field, title, onChange, initialValue }) => {
    const dispatch = useDispatch();
    const [fileKey, setFileKey] = useState(initialValue || null);
    const [isUploading, setUploading] = useState(false);

    const handleImageRemoval = () => {
        setFileKey(null);
        onChange(null);
    };

    const handleFileChange = async e => {
        const file = e.target.files?.[0];
        if (!file) return;

        // Randomize the filename
        const extension = file.name.split('.').pop();
        const newName = `${uuid()}.${extension}`;

        // Create a new File object with the new name
        const newFile = new File([file], newName, { type: file.type });

        if (file.size > 20 * 1024 * 1024) {
            toast.error(
                'File is too large. Please upload a file smaller than 20MB.'
            );
            return;
        }

        setUploading(true);

        try {
            // 1. Fetch the signed URL
            const signedUrl = await dispatch(
                getSignedUploadURL(newFile.name, true)
            );

            // 2. Upload the file using the signed URL
            await fetch(signedUrl, {
                method: 'PUT',
                body: newFile
            });

            const s3Url =
                'https://logosbucketpredev.s3.us-east-2.amazonaws.com/' +
                newFile.name;
            setFileKey(s3Url);
            onChange(s3Url);
        } catch (error) {
            toast.error('Error uploading image');
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="mt-2 flex flex-col justify-between gap-2 rounded-lg border border-dashed border-gray-900/25 px-6 py-4 w-full text-center items-center">
            <div className="w-full">
                <p className="text-sm leading-5 text-black text-center">
                    {title}
                </p>
                <hr className="my-2" />
            </div>

            {isUploading ? (
                <div className="h-20 w-20 flex items-center justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                </div>
            ) : fileKey ? (
                <img
                    src={fileKey}
                    className="rounded max-h-20"
                    aria-hidden="true"
                    alt={title}
                />
            ) : (
                <PhotoIcon
                    className="h-20 w-20 text-gray-300"
                    aria-hidden="true"
                />
            )}

            <div className="flex flex-col gap-2 items-center justify-center">
                <div className="flex text-sm w-full leading-6 text-gray-600">
                    <label className="w-full relative cursor-pointer rounded-md border border-blue-400 hover:border-blue-500 focus:border-blue-600 items-center justify-center font-semibold text-blue-600 focus-within:outline-none hover:text-blue-500">
                        <span className="text-center w-full p-4">
                            {fileKey ? 'Change Image' : 'Upload Image'}
                        </span>
                        <input
                            onChange={handleFileChange}
                            accept="image/*"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                        />
                    </label>
                </div>
                {fileKey && (
                    <div className="flex text-sm w-full leading-6 text-gray-600">
                        <button
                            onClick={handleImageRemoval}
                            className="w-full relative cursor-pointer rounded-md border border-red-400 hover:border-red-500 focus:border-red-600 items-center justify-center font-semibold text-red-600 focus-within:outline-none hover:text-red-500"
                        >
                            <span className="text-center w-full p-4">
                                Remove Image
                            </span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
