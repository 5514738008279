import { SUBMIT_HERO_EXPERIENCE } from '../../../api/graphQL';
import { clientWithAPIKey } from '../../../api/client';
const apiKey = process.env.REACT_APP_LANDING_PAGE_WIDGET_API_KEY;

// Initializes the chathistory directly and then disables text input

export const submitHeroExperience =
    ({ answers, userEmail, matchWithDev, budget, urgency, fileKeyS3 }) =>
    async (dispatch, getState) => {
        try {
            let answersFormatted = [];
            let idx = 0;

            for (let ans of answers) {
                let answerObj = createAnswer({
                    answer: ans,
                    role: 'user',
                    fileKeyS3
                });
                answersFormatted.push(answerObj);
                idx += 1;
            }

            const { data } = await clientWithAPIKey(apiKey).mutate({
                mutation: SUBMIT_HERO_EXPERIENCE,
                variables: {
                    answers: answersFormatted,
                    userEmail: userEmail,
                    matchWithDev,
                    budget,
                    urgency,
                    ad_params: localStorage.getItem('ad_params'),
                    referralLink: localStorage.getItem('referralLink')
                }
            });

            const project_id = data.submitHeroExperience;

            return project_id;
        } catch (error) {
            console.log({ error });
        }
    };

function createAnswer({ answer, username, image_url, fileKeyS3, role }) {
    return {
        content: answer,
        role: role,
        datetime: new Date().toISOString(),
        username,
        image_url,
        fileKeyS3
    };
}
